import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages

// const Main = React.lazy(()=>import('./views/Pages/main/index'))


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ReactNotification />
        <React.Suspense fallback={loading()}>
          <Switch>
            {/* <Route exact path="/" name="Main Page" render={props => <Main {...props}/>} /> */}

            <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
